import React from 'react'
// import { Link } from 'gatsby'

import Layout from '../components/layout'

const IndexPage = () => (
  <Layout>
    {/* <h1>Reggae Nusantara</h1> */}
    {/* <p>Salam damai</p> */}
    {/* <p>Now go build something great.</p> */}

    <main>
      <iframe className="spotify-frame animated fadeInUp delay-1s" src="https://open.spotify.com/embed/user/1276484743/playlist/7k65MC5qnKa2KUXXWGlNbe" title="Spotify Player" width="100%" height="500" frameBorder="0" allowtransparency="true" allow="encrypted-media"></iframe>
    </main>
  </Layout>
)

export default IndexPage
